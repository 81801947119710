<template>
  <site-header>
    <div class="border-primary mb-14"></div>
    <h1 class="mb-16 text-[2.5rem] font-bold uppercase text-white">
      Opérateurs funéraires<br />
      <span class="font-normal">Optimisez tous vos trajets !</span>
    </h1>

    <search-form
      class="left-5 right-5 max-md:absolute"
      @search="searchResult"
    ></search-form>
  </site-header>
  <!--  <section-wrapper class="bg-primary py-20 max-md:pt-72">
    <section-container mobile-padding class="max-w-container">
      <div
        class="mb-10 flex items-center justify-between max-md:justify-center"
      >
        <section-title heading="h2" invert>Trajets en cours</section-title>
      </div>

      <div
        v-if="initeraries.length > 0"
        class="grid gap-4 md:grid-cols-2 xl:grid-cols-4"
      >
        <ItineraryCard
          v-for="itinerary in initeraries"
          :key="itinerary.id"
          class="w-full"
          :route="itinerary"
          @click="showItinerary(itinerary)"
        ></ItineraryCard>
      </div>
      <div
        v-else
        class="flex h-32 items-center justify-center text-3xl font-bold opacity-30"
      >
        Aucun trajet en cours actuellement
      </div>
    </section-container>
  </section-wrapper>-->
  <section-wrapper class="py-28">
    <section-layer
      class="bg-no-repeat max-[1735px]:hidden"
      style="background-size: 30rem; background-position: left 0 top 0"
      background="/images/POINTILLÉS+PLAN.png"
    >
    </section-layer>

    <section-layer
      class="bg-left-top bg-no-repeat max-[1665px]:bg-[length:300px_300px] max-xl:bg-[length:150px_150px]"
      background="/images/ROND VIOLET.png"
    >
    </section-layer>

    <section-layer
      class="bg-right-bottom bg-no-repeat max-[1665px]:bg-[length:300px_300px] max-xl:bg-[length:150px_150px]"
      background="/images/ROND VERT.png"
    >
    </section-layer>

    <section-layer
      class="bg-no-repeat max-md:hidden"
      style="background-size: 30rem; background-position: right 30% top 0"
      background="/images/POINTILLÉS+CROIX.png"
    >
    </section-layer>

    <section-layer
      class="bg-no-repeat max-md:hidden"
      style="background-size: 20rem; background-position: right 50% bottom 0"
      background="/images/POINTILLÉS+MONTAGNE.png"
    >
    </section-layer>

    <section-layer
      class="bg-no-repeat max-md:hidden"
      style="background-size: 13rem; background-position: right 0 top 5rem"
      background="/images/POINTILLÉS+PIN.png"
    >
    </section-layer>

    <section-layer
      class="bg-no-repeat max-md:hidden"
      style="
        background-size: 40rem;
        background-position: left -20rem bottom -40rem;
      "
      background="/images/POINTILLÉS+POINT.png"
    >
    </section-layer>

    <section-container
      mobile-padding
      class="max-w-container relative max-md:pt-[14rem] lg:py-20"
    >
      <section-title class="mb-5">Le concept</section-title>

      <div class="grid-cols-1 gap-8 md:grid-cols-12 lg:grid">
        <div class="prose prose-funargos col-span-8 max-w-none max-lg:mb-12">
          <p><span class="font-semibold">Nous l’attendions tous !</span></p>
          <p>
            FUNARGOS, une solution efficace et fiable pour pouvoir enfin
            mutualiser et optimiser vos transports funéraires !
          </p>
          <p>
            Grâce à son rayonnement national, la communauté FUNARGOS vous
            permettra de combler vos trajets « à vide », sans défunt lors de vos
            transports funéraires.
          </p>
          <p>
            Pour un trajet de courte ou de longue distance, saisissez votre
            destination et les caractéristiques de votre transport sur la
            plateforme, un confrère sera forcément intéressé par votre offre !
          </p>
          <p>
            Bénéficiez de la réactivité, de la fluidité et de la sécurité de la
            technologie de FUNARGOS pour être mis en relation avec un autre
            chauffeur, pour réaliser un transport ou faire transporter un défunt
            le plus simplement possible quand et où vous voulez.
          </p>
        </div>
        <div class="col-span-4 flex items-center justify-center">
          <img class="mx-auto" src="/images/FRANCE.png" alt="map" />
        </div>
      </div>
    </section-container>
  </section-wrapper>

  <section-wrapper class="bg-background-3 pb-0 pt-11 xl:pt-24">
    <section-container class="max-w-container" mobile-padding>
      <div class="text-center">
        <section-title class="mb-10 md:mb-20">Quelques chiffres</section-title>

        <div class="grid md:grid-cols-2 xl:grid-cols-4">
          <div
            class="mb-10 flex w-full flex-col items-center justify-center text-center text-[#707070] xl:mb-0"
          >
            <circle-number
              class="mb-5"
              :loading="loadingStats"
              :number="stats.total_routes"
            ></circle-number>
            <p>
              <strong>Trajets</strong>
              créés
            </p>
          </div>
          <div
            class="mb-10 flex w-full flex-col items-center justify-center text-center text-[#707070] xl:mb-0"
          >
            <circle-number
              class="mb-5"
              :loading="loadingStats"
              :number="stats.total_alerts"
            ></circle-number>
            <p>
              <strong>Alertes</strong>
              créées
            </p>
          </div>
          <div
            class="mb-10 flex w-full flex-col items-center justify-center text-center text-[#707070] xl:mb-0"
          >
            <circle-number
              class="mb-5"
              :loading="loadingStats"
              :number="stats.total_drivers"
            ></circle-number>
            <p><strong>Chauffeurs</strong> enregistrés</p>
          </div>
          <div
            class="mb-14 flex w-full flex-col items-center justify-center text-center text-[#707070] xl:mb-0"
          >
            <circle-number
              class="mb-5"
              :loading="loadingStats"
              :number="stats.total_vehicles"
            ></circle-number>
            <p><strong>Véhicules</strong> enregistrés</p>
          </div>
        </div>
      </div>
    </section-container>
  </section-wrapper>
  <arrow-separator class="text-background-3 hidden xl:block"></arrow-separator>

  <section-wrapper
    class="mb-[5rem] pb-10 pt-10 md:pt-24 xl:-mt-[25rem] xl:pb-32"
  >
    <section-container mobile-padding class="max-w-container">
      <div class="text-center xl:text-left">
        <section-title class="md:md-20 mb-10 justify-center"
          >Nos services
        </section-title>
      </div>
      <div
        class="grid grid-cols-1 justify-items-center gap-10 xl:grid-cols-3 xl:gap-7"
      >
        <NuxtLink
          to="/nos-services#mise-en-relation"
          class="w-full md:w-1/2 xl:w-full"
        >
          <cta-card icon="persons" design="1"
            >Mise en relation des opérateurs funéraires
          </cta-card>
        </NuxtLink>
        <NuxtLink
          to="/nos-services#fonctionnement-des-services"
          class="w-full content-center md:w-1/2 xl:w-full"
        >
          <cta-card icon="engrenage" design="2"
            >Fonctionnement des services
          </cta-card>
        </NuxtLink>
        <NuxtLink
          to="/nos-services#nos-offres"
          class="w-full md:w-1/2 xl:w-full"
        >
          <cta-card icon="loupe" design="3">Découvrir nos offres </cta-card>
        </NuxtLink>
      </div>
    </section-container>
  </section-wrapper>
</template>

<script setup>
import dayjs from "dayjs";
import { useAuthStore } from "../store/auth";

const router = useRouter();
const initeraries = ref([]);
const { $api } = useNuxtApp();
const { me } = useAuthStore();

const loadingStats = ref(true);
const stats = ref({});

onMounted(() => {
  getIniteraries();
  getStats();
});

const showItinerary = (itinerary) => {
  router.push({
    name: "resultats",
    query: {
      origin: itinerary.origin_city.id,
      destination: itinerary.destination_city.id,
      date: dayjs(itinerary.start_min_at).format("YYYY-MM-DD"),
    },
  });
};

const formatDate = (date) => {
  return new Date(date).toLocaleDateString("fr-FR", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

const getIniteraries = async () => {
  const response = await $api
    .call("get", $api.route("routes.index"), null, {
      orderBy: "created_at",
  
      per_page: 4,
    })
    .then((data) => {
      initeraries.value = data;
    });
};

const searchResult = (payload) => {
  console.log(payload,'mrshah');
  router.push({
    name: "resultats",
    query: {
      origin: payload.origin?.id,
      destination: payload.destination?.id,
      date: payload.date ? dayjs(payload.date).format("YYYY-MM-DD") : undefined,
    },
  });
};

const getStats = () => {
  loadingStats.value = true;
  return $api
    .call("get", $api.route("stats"))
    .then((data) => {
      loadingStats.value = false;
      stats.value = data;
    })
    .catch((err) => {})
    .finally(() => {
      loadingStats.value = false;
    });
};
</script>
